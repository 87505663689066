import { loadStripe } from '@stripe/stripe-js';

class StripeClientClass {

    constructor() {
        this.stripe = null
        this.init()
    }

    async init() {
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK)
    }

    async getCardElement() {
        if(this.stripe === null) {
            await this.init()
        }
        let elements = this.stripe.elements()
        let card = await elements.create('card')
        return card
    }

    async addCard(card) {
        if(this.stripe === null) {
            await this.init()
        }
        return new Promise((resolve, reject) => {
            this.stripe.createToken(card).then((result) => {
                if (result.error) {
                    reject(result.error.message)
                }
                else {
                    resolve(result.token)
                }
            })

        })
    }

}

export const StripeClient = new StripeClientClass