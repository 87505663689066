<template lang="pug">
div.card-container
    template(v-if="exist == false")
        p.label AJOUTER UNE CARTE

        div(ref="card")
        p(v-if="errorMessage != ''") {{ errorMessage }}

        button.btn.btn-validate(@click="addCard()") Valider
    template(v-else)
        p.label CARTE DEJA AJOUTEE
</template>

<script lang="ts">
import { StripeClient } from '@/stripe/StripeClient'
import { Firestore } from '@/firebase/Firestore'

export default {

    computed: {
        user() {
            return this.$store.getters['auth/user']
        }
    },

    data() {
        return {
            card: null,
            errorMessage: '',
            exist: false
        }
    },

    methods: {
        addCard() {
            StripeClient.addCard(this.card)
                .then((token) => {
                    Firestore.collection('stripe_customers').doc(this.user.uid).collection('tokens').add({ token: token })
                        .then(() => {
                            this.$emit('card-added')
                        })
                })
                .catch((err) => {
                    this.errorMessage = err
                })
        }
    },

    async mounted() {
        this.card = await StripeClient.getCardElement()
        this.card.mount(this.$refs.card)

        Firestore.collection('stripe_customers').doc(this.user.uid).collection('sources').get()
            .then(documentSnapshot => {
                if(documentSnapshot.size > 0) {
                    this.exist = true
                }
            })
    }

}
</script>

<style lang="scss" scoped>
.card-container {
   .label {
        font-size: 11px;
        cursor: pointer;
        color: var(--greyd);
        margin-bottom: 10px;
    }
    .btn {
        margin-top: 20px;
    }
    .StripeElement {
        box-sizing: border-box;

        height: 40px;

        padding: 10px 12px;

        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
        width: 400px;
    }
    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }
    .StripeElement--invalid {
        border-color: #fa755a;
    }
    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
    p {
        color: #fa755a;
        margin-top: 10px;
        font-size: var(--fsmall);
    }
}
</style>